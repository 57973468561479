<template>
	<div class="settled-apply">
		<div class="loading" v-if="loading">
			<van-loading type="spinner" color="white" />
			<span>图片上传中...</span>
		</div>
		<div class="header">
			<img src="@/assets/img/logo.png" />
		</div>
		<h4 class="settled-title">门店入驻申请表</h4>
		<van-form @submit="onSubmit">
			<van-field v-model="form.nick_name" name="修理厂名称" label="修理厂名称" placeholder="修理厂名称"
				:rules="[{ required: true, message: '请填写修理厂名称' }]" />
			<van-field v-model="form.mobile" name="手机号" label="手机号" placeholder="手机号"
				:rules="[{ required: true, message: '请填写手机号' },{pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确手机号'}]" >
				<template #button>
					<van-button type="info" class="btn" size="mini" native-type="button" @click="statusQuery">申请状态查询</van-button>
				</template>
			</van-field>
			<van-field v-if="false" name="radio" label="修理厂类型">
				<template #input>
					<van-radio-group v-model="form.fac_type_id" direction="horizontal">
						<van-radio :name="item.value" v-for="(item, index) in FacTypeEnum.data" :key="index">
              {{ item.name }}
            </van-radio>
					</van-radio-group>
				</template>
			</van-field>
			<van-field v-if="form.fac_type_id == FacTypeEnum.STATION.value"
                 v-model="form.service_code" name="服务站代码" label="服务站代码" placeholder="服务站代码"
				:rules="[{ required: true, message: '请填写服务站代码' }]" />
			<van-field name="switch" label="可提供服务">
			  <template #input>
			    <van-switch v-model="form.fac_open" size="20" />
			  </template>
			</van-field>
			<van-field v-model="form.fac_address" name="修理厂地址" label="修理厂地址" placeholder="修理厂地址"
				:rules="[{ required: true, message: '请填写修理厂地址' }]" />
			<van-field name="uploader" label="营业执照" :rules="[{ required: true, message: '请上传营业执照' }]">
			  <template #input>
			    <van-uploader :max-count="1" v-model="license_image"
                        name='fac_license_image_id' :after-read="uploadImgs" />
			  </template>
			</van-field>
			<van-field name="uploader" label="门头照片" :rules="[{ required: true, message: '请上传门头照片' }]">
			  <template #input>
			    <van-uploader :max-count="1" v-model="image"
                        name='fac_image_id' :after-read="uploadImgs" />
			  </template>
			</van-field>
			<van-field name="uploader" label="内地场地照片" :rules="[{ required: true, message: '请上传内地场地照片' }]">
			  <template #input>
			    <van-uploader :max-count="1" v-model="inner_image"
                        name='fac_inner_image_id' :after-read="uploadImgs" />
			  </template>
			</van-field>
			<div style="margin: 16px;">
				<van-button class="btn" round block type="info" native-type="submit">提交</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
import { FacTypeEnum, ApplyStatusEnum } from '@/common/enum/user'
import * as LoginApi from '@/api/login'

	export default {
		data() {
			return {
				loading:false,
        FacTypeEnum,
        ApplyStatusEnum,
				form: {},
        license_image: [],
        image: [],
        inner_image: []
			};
		},
    created() {
      this.initForm()
    },
    methods: {
			statusQuery(){
				let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
				if(!this.form.mobile){
					this.$toast.fail('请输入手机号');
				}else if(!reg.test(this.form.mobile)){
					this.$toast.fail('请输入正确手机号');
				}else{
          LoginApi.userApplyInfo({mobile: this.form.mobile}).then((res) => {
            let info = res.data.info
            let message = ''

            if (!info) {
              message = '未申请'
            } else {
              console.log(info.status)
              message = ApplyStatusEnum[info.status].name + ' ' + (info.status_message ? info.status_message : '')
            }
            this.$dialog.alert({
              title: '申请状态',
              confirmButtonText: '知道了',
              message: message,
            }).then(() => {
              // on close
            });
          });
				}
			},
			onSubmit() {
        let data = JSON.parse(JSON.stringify(this.form))
        data.fac_open = data.fac_open ? 1 : 0
        LoginApi.userApply({ form: data }).then(() => {
            this.$toast.success('提交成功');
            this.initForm()
        });
			},
      initForm() {
        this.form = {
          nick_name: '',
          mobile: '',
          fac_type_id: FacTypeEnum.SHOP.value,
          service_code:'',
          fac_open:false,
          fac_address:'',
          fac_license_image_id:'',
          fac_image_id:'',
          fac_inner_image_id:''
        }
        this.license_image = []
        this.image = []
        this.inner_image = []
      },
      uploadImgs(file, name) {
        let app = this
				this.loading=true
				// 图片大于500kb就压缩
				if (file.file.size > 512000) {
					let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
					let context = canvas.getContext('2d')
					let img = new Image()
					img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
					let files = file;
					img.onload = () => {
						canvas.width = img.naturalWidth / 4
						canvas.height = img.naturalHeight / 4
						context.drawImage(img, 0, 0, canvas.width, canvas.height)
						files.content = canvas.toDataURL(files.file.type, 0.6) // 0.92为默认压缩质量
						let myFile = this.dataURLtoFile(files.content, files.file.name)//dataURLtoFile为自己封装的函数，将base64转为file
						let formData = new FormData()
						formData.append('file', myFile)
						this.$axios.post(process.env.VUE_APP_BASE_API + 'upload/image', formData, {
						  headers: {
						    'Content-Type': 'multipart/form-data'
						  }
						}).then(result => {
						  app.form[name.name] = result.data.data.fileInfo.file_id
							this.loading=false
						})
					}
				} else { //小于10M直接上传
					let formData = new FormData()
					formData.append('file', file.file)
					this.$axios.post(process.env.VUE_APP_BASE_API + 'upload/image', formData, {
					  headers: {
					    'Content-Type': 'multipart/form-data'
					  }
					}).then(result => {
					  app.form[name.name] = result.data.data.fileInfo.file_id
						this.loading=false
					})
				}
      },
			// 将base64转为file
			dataURLtoFile(dataurl, filename) {
				var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
				while(n--){
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], filename, {type:mime});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.settled-apply {
		.header {
			background: $main-color;
			padding: 15px;
		}

		.settled-title {
			padding: 15px;
			text-align: center;
		}
		.van-form{
			margin: 0 15px;
			border-radius: 10px;
			overflow: hidden;
		}
		.btn{
			background: $main-color;
		}
		.loading{
			position: fixed;
			background: rgba(0, 0, 0, 0.3);
			color: #FFF;
			width: 100vw;
			height: 100vh;
			z-index: 10000;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			span{
				margin: 10px 0 0 0;
			}
		}
	}
</style>
